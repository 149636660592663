import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Header from '../containers/SaasModern/Header';
import { saasModernTheme } from 'common/theme/saasModern';
import { ResetCSS } from 'common/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'containers/SaasModern/sassModern.style';
import Seo from 'components/seo';
import style from '../containers/SaasModern/Banner/banner.style';
import FunctionSection from '../containers/SaasModern/Function';
const Function = () => {
  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <Seo
          title='医療品在庫管理、 自動発注システムpitto'
          description='このページは医療品在庫管理、自動発注システムpittoのページです'
        />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Header />
          <FunctionSection />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
//

export default Function;
