import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import FeatureBlock from 'common/components/FeatureBlock';
import Image from 'common/components/Image';
import Container from 'common/components/UI/Container';
import Flip from 'react-reveal/Flip';
import { Tab, Tabs } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { StaticImage } from 'gatsby-plugin-image';
import style from './function.css';
import MediaQuery from 'react-responsive';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00a29a',
    },
  },
});

const FeatureSection = ({
  sectionWrapper,
  secTitleWrapper,
  secText,
  secHeading,
  secSubHeading,
  secSubText,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      saasModernJson {
        FEATURES {
          title
          description
          descriptionSub
          icon {
            publicURL
          }
        }
      }
    }
  `);
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box {...sectionWrapper} as='section' id='function_section'>
      <Container className={'container feature_container'}>
        <Box {...secTitleWrapper}>
          <Text {...secText} content='FUNCTION' />
          <Heading {...secHeading} content='機能' />
        </Box>
        <ThemeProvider theme={theme}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChange}
                aria-label='lab API tabs example'
                centered
                textColor='primary'
                indicatorColor='primary'
              >
                <Tab label={<div>在庫管理</div>} value='1' />
                <Tab label='入庫/使用' value='2' />
                <Tab label='発注履歴' value='3' />
                <Tab label='発注' value='4' />
              </TabList>
            </Box>
            <MediaQuery minWidth={1000}>
              {' '}
              <TabPanel value='2'>
                {' '}
                <div style={{ textAlign: 'center', position: 'relative' }}>
                  <div class='orderUIdescriptionBox orderUIdescriptionBox1_1'>
                    {' '}
                  </div>
                  <div class='orderUIdescriptionLine orderUIdescriptionLine1_1'>
                    {' '}
                  </div>
                  <div class='orderUIdescriptionTextBox orderUIdescriptionTextBox1_1'>
                    <div class='orderUIdescriptionTextWrapper'>
                      <div class='orderUIdescriptionTextHeader'>入庫/使用</div>
                      <span class='orderUIdescriptionTextBody'>
                        pittoで発効したQRコードをスキャンすると物品の使用や入庫を検知して、在庫数が増減します。数が少なくなってきた場合はカートに自動で追加されます。
                      </span>
                    </div>
                  </div>

                  <StaticImage
                    src='../../../common/assets/image/saasModern/iPad-Mockup3.png'
                    style={{
                      margin: '0 auto',
                      display: 'inline-block',
                      width: '40%',
                    }}
                    alt='logo'
                    quality='60'
                    placeholder='blurred'
                  />
                </div>
              </TabPanel>
              <TabPanel value='4'>
                <div style={{ textAlign: 'center', position: 'relative' }}>
                  <div class='orderUIdescriptionBox orderUIdescriptionBox2_1'></div>
                  <div class='orderUIdescriptionLine orderUIdescriptionLine2_1'></div>
                  <div class='orderUIdescriptionTextBox orderUIdescriptionTextBox2_1'>
                    <div class='orderUIdescriptionTextWrapper'>
                      <div class='orderUIdescriptionTextHeader'>
                        ディーラー毎に発注
                      </div>
                      <span class='orderUIdescriptionTextBody'>
                        〇〇円以上発注で送料無料など、細かいシチュエーションにも柔軟に対応できます。
                      </span>
                    </div>
                  </div>

                  <div class='orderUIdescriptionBox orderUIdescriptionBox2_2'></div>
                  <div class='orderUIdescriptionLine orderUIdescriptionLine2_2'></div>
                  <div class='orderUIdescriptionTextBox orderUIdescriptionTextBox2_2'>
                    <div class='orderUIdescriptionTextWrapper'>
                      <div class='orderUIdescriptionTextHeader'>自動発注</div>
                      <span class='orderUIdescriptionTextBody'>
                        使用した物品が事前に設定した発注点を下回った場合にチェックが入り、そのまま発注ボタンを押すだけでディーラーに注文が行われます。
                      </span>
                    </div>
                  </div>
                  <StaticImage
                    src='../../../common/assets/image/saasModern/iPad-Mockup4.png'
                    style={{
                      margin: '0 auto',
                      display: 'inline-block',
                      width: '40%',
                    }}
                    alt='logo'
                    quality='60'
                    placeholder='blurred'
                  />
                </div>
              </TabPanel>
              <TabPanel value='1'>
                {' '}
                <div style={{ textAlign: 'center', position: 'relative' }}>
                  <div class='orderUIdescriptionBox orderUIdescriptionBox3_1'></div>
                  <div class='orderUIdescriptionLine orderUIdescriptionLine3_1'></div>
                  <div class='orderUIdescriptionTextBox orderUIdescriptionTextBox3_1'>
                    <div class='orderUIdescriptionTextWrapper'>
                      <div class='orderUIdescriptionTextHeader'>
                        在庫確認/発注
                      </div>
                      <span class='orderUIdescriptionTextBody'>
                        どの物品の在庫が不足しているのか一目で確認することができます。カートアイコンをクリックすることで不足している物品を発注することもできます。
                      </span>
                    </div>
                  </div>
                  <div class='orderUIdescriptionBox orderUIdescriptionBox3_2'></div>
                  <div class='orderUIdescriptionLine orderUIdescriptionLine3_2'></div>
                  <div class='orderUIdescriptionTextBox orderUIdescriptionTextBox3_2'>
                    <div class='orderUIdescriptionTextWrapper'>
                      <div class='orderUIdescriptionTextHeader'>検索</div>
                      <span class='orderUIdescriptionTextBody'>
                        物品名から確認したい物品を検索できます。
                      </span>
                    </div>
                  </div>
                  <StaticImage
                    src='../../../common/assets/image/saasModern/iPad-Mockup1.png'
                    style={{
                      margin: '0 auto',
                      display: 'inline-block',
                      width: '40%',
                    }}
                    alt='logo'
                    quality='60'
                    placeholder='blurred'
                  />
                </div>
              </TabPanel>
              <TabPanel value='3'>
                <div style={{ textAlign: 'center', position: 'relative' }}>
                  <div class='orderUIdescriptionBox orderUIdescriptionBox4_1'></div>
                  <div class='orderUIdescriptionLine orderUIdescriptionLine4_1'></div>
                  <div class='orderUIdescriptionTextBox orderUIdescriptionTextBox4_1'>
                    <div class='orderUIdescriptionTextWrapper'>
                      <div class='orderUIdescriptionTextHeader'>Excel出力</div>
                      <span class='orderUIdescriptionTextBody'>
                        指定した期間の発注履歴をExcel形式で出力できるため、財務管理の手間が省けます。
                      </span>
                    </div>
                  </div>
                  <div class='orderUIdescriptionBox orderUIdescriptionBox4_2'></div>
                  <div class='orderUIdescriptionLine orderUIdescriptionLine4_2'></div>
                  <div class='orderUIdescriptionTextBox orderUIdescriptionTextBox4_2'>
                    <div class='orderUIdescriptionTextWrapper'>
                      <div class='orderUIdescriptionTextHeader'>
                        日付絞り込み
                      </div>
                      <span class='orderUIdescriptionTextBody'>
                        先月、今月の発注履歴はもちろん詳細な期間で絞り込みをかけて確認することができます。
                      </span>
                    </div>
                  </div>
                  <StaticImage
                    src='../../../common/assets/image/saasModern/iPad-Mockup2.png'
                    style={{
                      margin: '0 auto',
                      display: 'inline-block',
                      width: '40%',
                    }}
                    alt='logo'
                    quality='60'
                    placeholder='blurred'
                  />
                </div>
              </TabPanel>
            </MediaQuery>
            <MediaQuery maxWidth={999}>
              {' '}
              <TabPanel value='2'>
                <div style={{ textAlign: 'center', position: 'relative' }}>
                  <StaticImage
                    src='../../../common/assets/image/saasModern/iPad-Mockup3.png'
                    style={{
                      margin: '0 auto',
                      display: 'inline-block',
                      width: '100%',
                    }}
                    alt='logo'
                    quality='60'
                    placeholder='blurred'
                  />
                </div>
                <Text {...secSubText} content='使用' />
                <Heading
                  {...secSubHeading}
                  content='使用済みの物品をスキャンすると自動的に在庫の数が減少し、事前に決めておいた発注ポイントを下回った場合カートに追加されます。'
                />

                <Text {...secSubText} content='入庫' />
                <Heading
                  {...secSubHeading}
                  content='物品を発注した時、pittoからQRコードシールが発行されます。物品が到着した時にこのシールをスキャンしてから物品に貼り付けることで自動的に在庫の数が増加します。'
                />
              </TabPanel>
              <TabPanel value='4'>
                <div style={{ textAlign: 'center', position: 'relative' }}>
                  <StaticImage
                    src='../../../common/assets/image/saasModern/iPad-Mockup4.png'
                    style={{
                      margin: '0 auto',
                      display: 'inline-block',
                      width: '100%',
                    }}
                    alt='logo'
                    quality='60'
                    placeholder='blurred'
                  />
                </div>
                <Text {...secSubText} content='自動発注' />
                <Heading
                  {...secSubHeading}
                  content='物品を使用した場合はこちらの発注画面に物品が追加されます。使用した物品が事前に設定した発注点を下回っている場合には自動でチェックが入り、そのまま発注ボタンを押すだけでディーラーに注文が行われます。'
                />

                <Text {...secSubText} content='ディーラー毎に発注' />
                <Heading
                  {...secSubHeading}
                  content='カートの物品をディーラー毎に発注できるので、〇〇円以上発注で送料無料などの細かいシチュエーションにも柔軟に対応できます。'
                />
              </TabPanel>
              <TabPanel value='1'>
                {' '}
                <div style={{ textAlign: 'center', position: 'relative' }}>
                  <StaticImage
                    src='../../../common/assets/image/saasModern/iPad-Mockup1.png'
                    style={{
                      margin: '0 auto',
                      display: 'inline-block',
                      width: '100%',
                    }}
                    alt='logo'
                    quality='60'
                    placeholder='blurred'
                  />
                </div>
                <Text {...secSubText} content='在庫確認' />
                <Heading
                  {...secSubHeading}
                  content='シンプルな画面でどの物品がどこにどれだけあるのか。注文はしているかなどの情報が一目でわかります。物品の種類が多い場合は検索も可能です。'
                />
                <Text {...secSubText} content='手動注文' />
                <Heading
                  {...secSubHeading}
                  content='使用した物品だけでなく、在庫確認画面からも足りない物品をカートに追加して注文することができます。'
                />
              </TabPanel>
              <TabPanel value='3'>
                <div style={{ textAlign: 'center', position: 'relative' }}>
                  <StaticImage
                    src='../../../common/assets/image/saasModern/iPad-Mockup2.png'
                    style={{
                      margin: '0 auto',
                      display: 'inline-block',
                      width: '100%',
                    }}
                    alt='logo'
                    quality='60'
                    placeholder='blurred'
                  />
                </div>
                <Text {...secSubText} content='発注履歴' />
                <Heading
                  {...secSubHeading}
                  content='誰がいつ、何をどれだけ発注したのかかが一目でわかります。すでに物品が到着しているかどうかもわかるので二重に発注してしまうミスも防げます。'
                />
                <Text {...secSubText} content='詳細検索' />
                <Heading
                  {...secSubHeading}
                  content='先月分と今月分だけでなく期間を指定して詳細な発注履歴を確認することができます。'
                />
                <Text {...secSubText} content='CSV出力' />
                <Heading
                  {...secSubHeading}
                  content='指定した期間の発注履歴をExcelの形でダウンロードできます。これによって財務処理の負担も軽減することができます。'
                />
              </TabPanel>
            </MediaQuery>
          </TabContext>
        </ThemeProvider>
      </Container>
    </Box>
  );
};

FeatureSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secText: PropTypes.object,
  secHeading: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  FeatureItemStyle: PropTypes.object,
  iconStyle: PropTypes.object,
  contentStyle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
};

FeatureSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['100px', '100px', '100px', '140px', '160px'],
    pb: ['60px', '80px', '80px', '80px'],
  },
  secTitleWrapper: {
    mb: ['55px', '65px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: 'primary',
    mb: '5px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '700',
    color: '#696969',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67',
  },
  secSubText: {
    fontSize: ['16px', '24px'],
    fontWeight: '700',
    color: 'primary',
    letterSpacing: '-0.025em',
    mb: '0',
    mt: '10px',
    lineHeight: '1.67',
  },
  secSubHeading: {
    fontSize: ['12px', '24px'],
    fontWeight: '500',
    color: '#696969',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67',
  },
};

export default FeatureSection;
